import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import YebraContacto from "../stylesheets/assets/carlos-contacto.jpg"

// import { rhythm } from '../utils/tipography';

const ContactoPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="contact bgGrey ofsTop margTop ofsBottom tCenter">
        {/*Container*/}
        <div className="container">
          <div className="sixteen columns ">
            <div className="contact-hero">
              {/*Background image*/}
              <div className="background-image " style={{ backgroundImage: "url(" + YebraContacto + ")" }}>
                <img alt="" className src={YebraContacto} style={{display: 'none'}} />
              </div>
              {/*End background image*/}
            </div>
          </div>
        </div>
        {/*End container*/}
        {/*Container*/}
        <div className="container">
          <div className="sixteen columns ">
            <div className="eight columns contact-info info-left bgDark tLeft">
            Carlos Yebra




              <h1>Necesitas algunas fotos ?</h1>	
              <p> CARLOS YEBRA<br />Email / <a href="#">carlosyebra@gmail.com</a><br />Teléfono / +34 956 457 443
              <br />Móvil / +34 639 726 780</p>
              <p>
                Dirección<br />
                Calle gaviota<br />
                Zahara de los Atunes. 11393.<br />
                (Cádiz)
              </p>
            </div>
            <div className="eight columns contact-form info-right bgWhite tLeft">
              <h1>send a message !</h1>
              <form action="send.php" method="post">
                <input type="text" name="name" id="name"  placeholder="Nombre *" />
                <input type="text" name="email" id="email"  placeholder="Email *" />
                <textarea rows={10} cols={45} id="message" placeholder="Mensaje *" name="message"  />
                <input type="submit" defaultValue="Enviar Mensaje" className="submit" />
              </form>
              {/*Reply form message*/}
              <div id="success"><h2>Your message has been sent. Thank you!</h2></div>
              <div id="error"><h2>Sorry your message can not be sent.</h2></div>
              {/*End reply form message*/}
            </div>
          </div>
        </div>
        {/*End container*/}
      </section>
  </Layout>
)

export default ContactoPage
